<template>
  <div id="banners">
    <!-- Content -->
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader
            class="d-flex justify-content-between align-items-center"
          >
            <h4 class="mb-0">Banners Iveco Capital</h4>
            <div class="d-flex">
              <router-link
                v-if="datatable.items && datatable.items.length > 0"
                to="/banners-financing/order"
                class="btn mr-3 d-flex align-items-center btn-secondary"
              >
                <CIcon name="cil-list-high-priority" class="mr-2" />
                Ordenar
              </router-link>
              <router-link
                to="/banners-financing/create"
                class="btn btn-primary d-flex align-items-center"
              >
                <CIcon name="cil-library-add" class="mr-2" />
                Incluir
              </router-link>
            </div>
          </CCardHeader>
          <CCardBody>
            <!-- Search -->
            <CRow>
              <CCol col="12">
                <Search @search="updateSearch" />
              </CCol>
            </CRow>
            <CRow>
              <CCol col="12">
                <CRow>
                  <CCol md="4" lg="4" xl="3">
                    <div class="form-group">
                      <Multiselect
                        :value="
                          statusOptions.find(
                            (option) => option.value === query.status
                          )
                        "
                        :options="statusOptions"
                        @select="(selected) => (query.status = selected.value)"
                      />
                    </div>
                  </CCol>
                  <CCol md="4" lg="4" xl="3">
                    <div class="form-group">
                      <Multiselect
                        :value="
                          situationOptions.find(
                            (option) => option.value == query.situation
                          )
                        "
                        :options="situationOptions"
                        @select="
                          (selected) => (query.situation = selected.value)
                        "
                      />
                    </div>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <!-- Datatable -->
            <CRow>
              <CCol col="12">
                <DataTable
                  :sorter="query.sorter"
                  :items="datatable.items"
                  :fields="datatable.fields"
                  :enable-export="false"
                  base-route="/banners-financing/edit/"
                  @sorterValue="(event) => (query.sorter = event)"
                  @row-clicked="handleRowClick"
                  @update:checked="updateStatus"
                >
                  <template #actions="{ id }">
                    <DatatableEdit :url="`/banners-financing/edit/${id}`" />
                    <DatatableRemove
                      @row-clicked="handleRowClick"
                      @deleted="deleteBanner"
                    />
                  </template>
                </DataTable>
              </CCol>
            </CRow>

            <!-- Pagination -->
            <CRow class="mt-2">
              <CCol lg="12">
                <Pagination
                  :page="query.page"
                  :per-page="query.perpage"
                  :pages="pagination.pages"
                  :total="pagination.total"
                  :items="pagination.items"
                  @update:active-page="(page) => (query.page = page)"
                  @update:perpage="(perpage) => (query.perpage = perpage)"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import DataTable from '@/components/ui/Datatable'
import DatatableEdit from '@/components/ui/DatatableActions/Edit'
import DatatableRemove from '@/components/ui/DatatableActions/Remove'
import Pagination from '@/components/ui/Pagination'
import Search from '@/components/ui/Search'
import Multiselect from '@/components/ui/Multiselect'
import BannerFinancingService from '@/services/banner-financing.service'

export default {
  metaInfo: {
    title: 'Banners Iveco Capital',
    titleTemplate: 'Manager - Rodonaves Iveco - %s'
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.state.auth.permissions.includes('manage_banners')) {
        vm.$router.push('/404')
      }
    })
  },

  components: {
    DataTable,
    DatatableEdit,
    DatatableRemove,
    Pagination,
    Search,
    Multiselect
  },

  data() {
    return {
      datatable: {
        items: [],
        fields: [
          {
            key: 'status',
            label: 'Status',
            _style: 'width: 5rem;',
            sorter: false
          },
          {
            key: 'order',
            label: 'Ordem',
            _style: 'width:7rem;',
            sorter: true
          },
          {
            key: 'heading',
            label: 'Banner',
            sorter: true,
            thumb: true
          },
          {
            key: 'period',
            label: 'Tempo',
            sorter: false
          },
          {
            key: 'time',
            label: 'Exibição',
            sorter: true
          },
          {
            key: 'situation',
            label: 'Situação',
            sorter: false
          },
          {
            key: 'actions',
            label: 'Ações',
            sorter: false,
            _style: 'width: 12%;'
          }
        ]
      },
      query: {
        search: '',
        page: 1,
        perpage: localStorage.getItem('perPageItem')
          ? parseFloat(localStorage.getItem('perPageItem'))
          : 10,
        pagination: true,
        status: null,
        situation: null,
        sorter: {
          asc: true,
          column: 'order'
        }
      },
      pagination: {
        total: 0,
        pages: 0,
        items: 0
      },
      statusOptions: [
        {
          value: null,
          label: 'Todos os status'
        },
        {
          label: 'Ativo',
          value: true
        },
        {
          label: 'Inativo',
          value: false
        }
      ],
      situationOptions: [
        {
          label: 'Todas as situações',
          value: null
        },
        {
          label: 'Expirado',
          value: 'expired'
        },
        {
          label: 'Vigente',
          value: 'current'
        },
        {
          label: 'Agendado',
          value: 'scheduled'
        }
      ],
      rowCliked: {}
    }
  },

  watch: {
    query: {
      handler: 'updateDataTable',
      deep: true
    }
  },

  async created() {
    await this.updateDataTable()
  },

  mounted() {
    localStorage.setItem('bannersfinancingOrder', 'updated_at')
  },

  methods: {
    async updateDataTable() {
      const response = await BannerFinancingService.getAll(this.query)
      const { data, pagination } = response

      if (data) {
        this.pagination = {
          ...pagination,
          pages: pagination.lastPage,
          items: data.length
        }

        const newItems = response.data.map((item) => {
          return {
            ...item,
            thumb: item.medias.length > 0 ? item.medias[0].url : null
          }
        })

        this.datatable.items = newItems
      }
    },

    async updateSearch(searchText) {
      this.query.search = searchText
      this.query.page = 1

      await this.updateDataTable()
    },

    handleRowClick(event) {
      this.rowCliked = event
    },

    async updateStatus(event) {
      const response = await BannerFinancingService.updateStatus(
        this.rowCliked,
        event
      )

      if (response.status === 200) {
        this.$notify({
          group: 'notifications',
          type: 'success',
          text: 'Status alterado com sucesso'
        })
        await this.updateDataTable()
      } else {
        this.$notify({
          group: 'notifications',
          type: 'error',
          text: 'Ocorreu um erro ao atualizar o status, por favor tente novamente!'
        })
      }
    },

    async deleteBanner() {
      const response = await BannerFinancingService.deleteBanner(this.rowCliked)

      if (response.status === 200) {
        this.$notify({
          group: 'notifications',
          type: 'success',
          text: 'Banner excluído com sucesso!'
        })
      } else {
        this.$notify({
          group: 'notifications',
          type: 'error',
          text: 'Não foi possivel excluir o banner, por favor tente novamente'
        })
      }

      await this.updateDataTable()
    }
  }
}
</script>

<style lang="scss">
#banners {
  .orderButton {
    margin-right: 10px;
  }

  .thumb {
    width: 80px !important;
  }
}
</style>
